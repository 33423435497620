import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
import { HelperService } from '../services/helper-service';
import VueToast from 'vue-toast-notification';
export class NewsletterSubscriptionVueComponent extends Vue {
    constructor() {
        super(...arguments);
        this.getBoundData = () => {
            return {
                model: this.createNewsletterSubscriptionModel()
            };
        };
    }
    createNewsletterSubscriptionModel() {
        return {
            name: '',
            email: ''
        };
    }
    submitClick() {
        const vm = this;
        var modelData = Object.assign({}, vm.model);
        var url = `api/newslettersubscriptionsapi/subscribe`;
        var data = JSON.stringify(modelData);
        $.ajax({
            url: url,
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            data: data,
            success: () => {
                Vue.$toast.open({ message: 'You have subscribed to our newsletter.  You\'ll hear from us soon.  Thank you', type: 'success', position: 'top' });
                HelperService.goToTop();
            },
            error: () => {
                Vue.$toast.open({ message: 'Oops, sorry but that didn\'t process.  Please use our contact form', type: 'info', position: 'top' });
            }
        });
    }
    ;
    getBoundMethods() {
        return {
            createNewsletterSubscriptionModel: this.createNewsletterSubscriptionModel,
            submitClick: this.submitClick
        };
    }
}
// Initialise any template components.
Vue.use(VueToast);
// Setup veevalidate
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
extend('required', required);
extend('email', email);
// Initialise our main component.
const newsletterSubscriptionVueComponent = new NewsletterSubscriptionVueComponent();
new Vue({
    el: "#app-newsletter-subscription",
    data: newsletterSubscriptionVueComponent.getBoundData,
    methods: newsletterSubscriptionVueComponent.getBoundMethods()
});
