export class HelperService {
    static getAgeSelectOptions() {
        return ['3 yrs', '4 yrs', '5 yrs', '6 yrs', '7 yrs', '8 yrs', 'Other'];
    }
    static getISOStringFromDDMMYYY(date) {
        var isoDate = null;
        if (date == null || date.length == 0) {
            return null;
        }
        if (date.indexOf("-") > -1) {
            var parts = date.split('-');
            if (parts.length != 3) {
                return null;
            }
            isoDate = `${parts[0]}-${parts[1]}-${parts[2]}T00:00:00.000Z`;
            return isoDate;
        }
        var parts = date.split('/');
        if (parts.length != 3) {
            return null;
        }
        isoDate = `${parts[2]}-${parts[1]}-${parts[0]}T00:00:00.000Z`;
        return isoDate;
    }
    ;
    static goToTop() {
        window.scrollTo(0, 0);
    }
}
